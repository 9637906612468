import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface IEmployeeContact {
	title: string;
	employeeName: string;
	phoneNumber: string;
	avatar: FluidObject;
	email: string;
}

export const useEmployeeContacts = (): IEmployeeContact[] => {
	const {
		strapiContact: { employeeContact },
	} = useStaticQuery(graphql`
    query useEmployeeContacts {
        
      strapiContact {
        employeeContact {
          title
          employeeName
          phoneNumber
          email
          avatar {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

	return employeeContact.map((contact: any) => ({
		...contact,
		avatar: contact.avatar.childImageSharp.fluid
	}));
};
