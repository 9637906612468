import GoogleMapReact from "google-map-react";
import { styled } from "linaria/react";
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { theme } from "../../../Shared/Theme/Theme";

export type GoogleMapProps = React.HTMLAttributes<HTMLElement>;

const isClient = typeof window !== "undefined";

const Marker = FaMapMarkerAlt as any;

const GoogleMapInner: React.FC<GoogleMapProps> = (props: GoogleMapProps) => {
	const center = { lat: 48.2075783, lng: 19.3471894 };
	return (
		<section className={props.className}>
			{isClient && (
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyBpg7gOHO_jF3wGQkV3GV26cuO5KlsFCbM" }}
					defaultCenter={center}
					defaultZoom={16}
				>
					<div {...center}>
						<Marker className="marker" />
					</div>
				</GoogleMapReact>
			)}
		</section>
	);
};

export const GoogleMap = styled(GoogleMapInner) <GoogleMapProps>`
  display: flex;
  height: 100%;

  .marker {
    fill: ${theme.color.primary};
    font-size: 4rem;
    transform: translate(-50%, -100%);
  }
`;
