import { styled } from "linaria/react";
import React from "react";
import { muiTheme } from "../Theme/Theme";
import { EmployeeContact } from "./components/EmployeeContact/EmployeeContact";
import { useEmployeeContacts } from "./hooks/useEmployeeContacts";

export interface EmployeeContactsProps {
  className?: string;
}

const EmployeeContactsInner = (props: EmployeeContactsProps) => {
  const employeeContacts = useEmployeeContacts();

  return (
    <div className={props.className}>
      {employeeContacts.map((contact) => (
        <EmployeeContact key={contact.title} {...contact} />
      ))}
    </div>
  );
};

export const EmployeeContacts = styled(EmployeeContactsInner)`
  display: flex;
  justify-content: space-evenly;

  ${muiTheme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`;
