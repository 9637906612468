import React from "react";
import { ComeTalkWithUs } from "../components/Contact/ComeTalkWithUs/ComeTalkWithUs";
import { GetInTouch } from "../components/Contact/GetInTouch/GetInTouch";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt" description="This is a contact page" />
    <GetInTouch />
    <ComeTalkWithUs />
  </Layout>
);

export default ContactPage;
