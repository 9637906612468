import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface IContactInfo {
    address: string
    businessId: string
    vatId: string;
    orText: string
}

export const useContactInfo = (): IContactInfo => {
	const {
		strapiContact,
	} = useStaticQuery(graphql`
    query useContactInfo {
      strapiContact {
          address
          businessId
          vatId
          orText
      }
    }
  `);

	return strapiContact
};
