import GatsbyImage from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Heading } from "../../../Heading/Heading";
import { muiTheme, theme } from "../../../Theme/Theme";
import { IEmployeeContact } from "../../hooks/useEmployeeContacts";

export interface EmployeeContactProps extends IEmployeeContact {
  className?: string;
}

const titleStyles = css`
  margin-bottom: 2.4rem;
  .heading {
    font-size: 2.4rem;
    font-weight: 500;
  }

  *::after {
    height: 3px;
    bottom: -8px;
    width: 55px;
  }
`;

const ImageWrapper = styled.div`
  height: 130px;
  width: 130px;
  border-radius: 50%;
  overflow: hidden;

  margin-right: 50px;


  ${muiTheme.breakpoints.down('sm')} {
    height: 100px;
    width: 100px;
    margin-right: 30px;
  }
`;

const EmployeeName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
`;

const Info = styled.div``;

const ContactLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  text-decoration: none;
  color: ${theme.color.primaryDark};
`;

const ContactLinks = styled.div`
  display: flex;
  flex-flow: column;
`;

const EmployeeContactInner = (props: EmployeeContactProps) => {
  const { title, employeeName, avatar, phoneNumber, email } = props;
  return (
    <article className={props.className}>
      <ImageWrapper>
        <GatsbyImage fluid={avatar} />
      </ImageWrapper>
      <Info>
        <Heading heading={title} className={titleStyles} />
        <EmployeeName>{employeeName}</EmployeeName>
        <ContactLinks>
          <ContactLink href={`tel:${phoneNumber}`}>{phoneNumber}</ContactLink>
          <ContactLink
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${email}`}
          >
            {email}
          </ContactLink>
        </ContactLinks>
      </Info>
    </article>
  );
};

export const EmployeeContact = styled(EmployeeContactInner)`
  margin-top: 6rem;
  display: flex;
  align-items: center;

  ${muiTheme.breakpoints.down('sm')} {
    justify-content: center;
    &:first-child {
      margin-top: 0rem;
    }
  }
`;
