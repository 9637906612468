import { Container } from "@material-ui/core";
import { css } from "linaria";
import { styled } from "linaria/react";
import { rgba } from "polished";
import React from "react";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { theme } from "../../Shared/Theme/Theme";
import { GoogleMap } from "./components/GoogleMap";
import {useContactInfo} from "./hooks/useContactInfo";

export interface ComeTalkWithUsProps {
  className?: string;
}

const headingStyles = css`
  text-align: center;
  margin-bottom: 20px;

  *::after {
    right: 0;
    left: 0;
    margin: auto;
  }

  .subheading {
    margin: 35px auto;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
`;

const mapStyles = css`
  height: 600px;
`;

const Address = styled.b`
  font-weight: 500;
  margin-right: 5px;
  filter: opacity(0.9);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const InfoCompanyRegistered = styled.div`
  font-size: 1.4rem;
  margin-top: 20px;
  color: ${rgba(theme.color.text, 0.5)};
`;

const ComeTalkWithUsInner = (props: ComeTalkWithUsProps) => {
    const data = useContactInfo()
  return (
    <Section className={props.className}>
      <Container>
        <header>
          <Heading
            className={headingStyles}
            heading="Informácie o spoločnosti"
            subheading={
              <InfoWrapper>
                <div>
                  <Address>Adresa: </Address>{data?.address}
                </div>
                <div>
                  <Address>IČO: </Address>{data?.businessId}
                </div>
                <div>
                  <Address>IČ DPH: </Address>{data?.vatId}
                </div>
                <InfoCompanyRegistered>
                    {data?.orText}
                </InfoCompanyRegistered>
              </InfoWrapper>
            }
          />
        </header>
      </Container>
      <MapWrapper>
        <GoogleMap className={mapStyles} />
      </MapWrapper>
    </Section>
  );
};

export const ComeTalkWithUs = styled(ComeTalkWithUsInner)`
  margin-bottom: 0 !important;
  padding-bottom: 0;
`;
