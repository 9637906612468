import { Container, Grid } from "@material-ui/core";
import { styled } from "linaria/react";
import React from "react";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";
import { EmployeeContact } from "../../Shared/EmployeeContacts/components/EmployeeContact/EmployeeContact";
import { useEmployeeContacts } from "../../Shared/EmployeeContacts/hooks/useEmployeeContacts";
import { EmployeeContacts } from "../../Shared/EmployeeContacts/EmployeeContacts";

export interface GetInTouchProps {
  className?: string;
}

const GetInTouchInner = (props: GetInTouchProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <header>
          <Heading
            heading="Spojte sa s nami!"
            subheading="Ak máte akékoľvek otázky, neváhaje a kontaktujte nás, či už mailom alebo nám rovno zavolajte "
            pattern
          />
        </header>
        <EmployeeContacts />
      </Container>
    </Section>
  );
};

export const GetInTouch = styled(GetInTouchInner)``;
